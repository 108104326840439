/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {Button, Badge} from '@material-ui/core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ajax from '../../../../../app/utils/ajax'
import {useGlobalData} from '../../../../../app/hooks/useGlobalData'
import './AsideMenuList.scss'

const MenuEntry = (props) => {
  const location = useLocation()
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
        ? " menu-item-active menu-item-open "
        : "";
  }

  return (
    <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(props.path)}`}
        aria-haspopup="true"
        data-menu-toggle="hover"

    >
      <NavLink className="menu-link menu-toggle" to={props.path}>
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/" + props.iconPath)}/>
        </span>

        <span className="menu-text">{props.label}</span>
        {
          props.badge > 0 && (
            <span className='badge'>
              <div>{props.badge}</div>
            </span>
          )
        }

      </NavLink>
    </li>
  )
}

export function AsideMenuList({ layoutProps }) {
  const globalData = useGlobalData()

  const roleLabels = {
    'admin': 'Administrateur',
    'adherent': 'Adhérent',
    'commercial': 'Commercial',
    'commercial+fr': 'Commercial + FR/Tarifs',
    'marketing': 'Marketing',
    'formateur': "Formateur",
    "vendeur": "Vendeur",
    "reyt": "Collaborateur Reyt", 
    "annuaire": "Annuaires/Catalogues"
  }

  const logout = () => {
    window.localStorage.removeItem('persist:demo1-auth')
    window.localStorage.removeItem('token')
    window.location = '/'
  }

  return (
      <>
        <ul className={`menu-nav ${layoutProps.ulClasses}`} style={{paddingTop: 0}}>

          {
            globalData.user && (
              <div style={{display: 'flex', flexDirection: 'column', padding: "9px 25px", marginBottom: '10px'}}>
                {
                  globalData.user && (
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      {
                        globalData.user.picture
                        ? <img height={38} width={38} style={{objectFit: 'cover', objectPosition: 'center', borderRadius: '50%'}} src={'/api/media/thumbnail/' + globalData.user.picture} />
                      : (
                        <div style={{borderRadius: '50%', padding: '5px', backgroundColor: '#EEF0F8', width: '38px', height: '38px'}}>
                          <img height={28} src={'/media/svg/icons/General/User.svg'} />
                        </div>
                      )}
                      <div style={{marginLeft: '10px', color: '#a2a3b7', display: 'flex', flexDirection: 'column'}}>
                        <div>{globalData.user.firstName + ' ' + globalData.user.lastName}</div>
                        <div style={{fontStyle: 'italic'}}>{roleLabels[globalData.user.role] || ''}</div>
                      </div>
                    </div>
                  )
                }
                {
                  globalData.user && (
                    <div style={{marginTop: '5px'}}>
                      <NavLink className="menu-link menu-toggle" to={'/mon-profil'}>
                        <Button size="small" variant="outlined" color="secondary">
                          <FontAwesomeIcon icon='user' style={{marginRight: '15px'}} />
                          Mon Profil
                        </Button>
                      </NavLink>
                    </div>
                  )
                }

                <div style={{marginTop: '5px'}}>
                  <Button size="small" variant="outlined" color="secondary" onClick={logout}>
                    <FontAwesomeIcon icon='sign-out-alt' style={{marginRight: '15px'}} />
                    Se déconnecter
                  </Button>
                </div>
              </div>
            )
          }

          {globalData.user && globalData.user.role !== 'reyt' && globalData.user.role !== 'annuaire' && <MenuEntry path='/' iconPath='Home/Home.svg' label='Accueil' />}

          {
            globalData.user && globalData.user.role !== 'reyt' && globalData.user.role !== 'annuaire' && (
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '9px 25px', paddingTop: 0}}>
                <a target="_blank" href={"https://cloud.comptoirdelours.fr/nextcloud"} style={{width: '100%'}}>
                  <Button variant="outlined" color="primary" size="large" style={{width: '100%'}}>
                    <FontAwesomeIcon icon='file' style={{marginRight: '15px'}} />
                    Accéder au cloud
                  </Button>
                </a>
              </div>
            )
          }

          {
            globalData.user && globalData.user.role !== 'reyt' && globalData.user.role !== 'annuaire' && (globalData.user.role === 'admin' || globalData.user.cdo) && (
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', width: '100%', padding: '9px 25px', paddingTop: 0}}>
                <a target="_blank" href={"https://cdo.ceiweb.fr/"} style={{width: '100%'}}>
                  <Button variant="outlined" color="primary" size="large" style={{width: '100%'}}>
                    <FontAwesomeIcon icon='bullhorn' style={{marginRight: '15px'}} />
                    Objets pub CDO
                  </Button>
                </a>
              </div>
            )
          }

          {/*
            globalData.user && globalData.user.role !== 'reyt' && (globalData.user.role === 'admin' || globalData.user.hbf) && (
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', width: '100%', padding: '9px 25px', paddingTop: 0}}>
                <a target="_blank" href={"https://hbf.ceiweb.fr/"} style={{width: '100%'}}>
                  <Button variant="outlined" color="primary" size="large" style={{width: '100%'}}>
                    <FontAwesomeIcon icon='bullhorn' style={{marginRight: '15px'}} />
                    Objets pub HBF
                  </Button>
                </a>
              </div>
            )
          */}

          {
            (globalData.user && globalData.user.role !== 'reyt' && globalData.user.role === 'admin' && globalData.user.role !== 'annuaire') && (
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', width: '100%', padding: '9px 25px', paddingTop: 0}}>
                <a href={"https://intranetv2.comptoirdelours.fr/api/grand-jeu/csv?token=" + window.localStorage.getItem("token")} style={{width: '100%'}}>
                  <Button variant="outlined" color="primary" size="large" style={{width: '100%'}}>
                    <FontAwesomeIcon icon='car' style={{marginRight: '15px'}} />
                    Grand Jeu
                  </Button>
                </a>
              </div>
            )
          }

          <li className="menu-section ">
            <h4 className="menu-text">Catalogue</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <MenuEntry path='/produits' iconPath='Shopping/Box2.svg' label='Produits' />
          {globalData.user && (globalData.user.role === 'marketing' || globalData.user.role === 'admin') && globalData.user.role !== 'annuaire' && <MenuEntry path='/marques' iconPath='Shopping/Barcode.svg' label='Marques' />}
          {globalData.user && globalData.user.role !== 'marketing' && globalData.user.role !== 'vendeur' && <MenuEntry path='/fournisseurs' iconPath='Clothes/Shirt.svg' label='Fournisseurs' />}
          {
            globalData.user && (globalData.user.role === 'admin' || globalData.user.role === 'marketing' || globalData.user.role === 'annuaire') && (
              <MenuEntry path='/catalogues' iconPath='Home/Book-open.svg' label='Catalogues' />
            )
          }

          {
            globalData.user && globalData.user.role !== 'reyt' && (
              <>
                <li className="menu-section ">
                  <h4 className="menu-text">Groupement</h4>
                  <i className="menu-icon flaticon-more-v2"></i>
                </li>

                {globalData.user.role !== 'annuaire' && <MenuEntry path='/faq' iconPath='Code/Question-circle.svg' label='Foire aux questions' />}
                <MenuEntry path='/annuaire' iconPath='Communication/Adress-book1.svg' label='Annuaire' />
                <MenuEntry path='/contact-fournisseurs' iconPath='Communication/Adress-book1.svg' label='Annuaire fournisseurs' />
                <MenuEntry path='/contact-prestataires' iconPath='Communication/Adress-book1.svg' label='Annuaire prestataires' />
                {globalData.user.role !== 'annuaire' && <MenuEntry path='/adherents' iconPath='Communication/Adress-book2.svg' label='Adhérents' />}
                <MenuEntry path='/points-de-vente' iconPath='Map/Marker1.svg' label='Points de vente' />
                {globalData.user && globalData.user.role !== 'marketing' && globalData.user.role !== 'vendeur' && globalData.user.role !== 'annuaire' && <MenuEntry path='/reunions' iconPath='Home/Clock.svg' label='Réunions / Évènements' />}
                {globalData.user && globalData.user.role !== 'marketing' && globalData.user.role !== 'vendeur' && globalData.user.role !== 'annuaire' && <MenuEntry path='/commissions' iconPath='Home/Book.svg' label='Commissions' />}
                {globalData.user && globalData.user.role !== 'marketing' && globalData.user.role !== 'annuaire' && <MenuEntry path='/bonnes-affaires' iconPath='General/Like.svg' label='Bonnes affaires' />}
                {(globalData.user && globalData.user.role !== 'marketing') && globalData.user.role !== 'annuaire' && <MenuEntry path='/commandes' iconPath='Communication/Clipboard-list.svg' label='Commandes' />}
                {(globalData.user && globalData.user.role !== 'marketing') && globalData.user.role !== 'annuaire' && <MenuEntry path='/sondages' iconPath='Communication/Chat6.svg' label='Sondages' />}

                {
                  globalData.user && globalData.user.role === 'admin' && (
                    <>
                      <li className="menu-section ">
                        <h4 className="menu-text">Administration</h4>
                        <i className="menu-icon flaticon-more-v2"></i>
                      </li>

                      <MenuEntry path='/utilisateurs' iconPath='General/User.svg' label='Utilisateurs' />
                      <MenuEntry path='/articles' iconPath='Home/Book-open.svg' label='Articles' />
                      <MenuEntry path='/validations' iconPath='General/Shield-protected.svg' label='Élements à valider' badge={globalData.validationsCount} />
                    </>
                  )
                }
              </>
            )
          }
        </ul>
      </>
  );
}
