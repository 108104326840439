import React, {useState, useEffect, useRef} from "react";
import { Table, Form, Col, Modal } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import './Affiliates.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import {TextField, Grid, Paper, Card, CardContent, CardActions, Typography, Button, TableCell, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, Switch, Checkbox} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { cb } from "react-syntax-highlighter/dist/esm/styles/prism";
import async from 'async'
import {useGlobalData} from '../hooks/useGlobalData'
import ResourceList from '../components/ResourceList/ResourceList'
import PlacesAutocomplete from '../components/PlacesAutocomplete/PlacesAutocomplete'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr"

library.add(fas)

export const Catalogs = () => {
  const [affiliates, setAffiliates] = useState(null)
  const [affiliateList, setAffiliateList] = useState(null)
  const [users, setUsers] = useState(null)
  const [usersById, setUsersById] = useState(null)

  const globalData = useGlobalData()
  const user = globalData.user

  const saveBlob = (function () {
    var a = document.createElement("a")
    document.body.appendChild(a)
    a.style = "display: none"
    return function (blob, fileName) {
      var url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }())

  const removeQuestion = (catalog) => {
    return `Voulez-vous vraiment supprimer le catalogue ${catalog.name} ?`
  }

  const getNewCatalog = () => {
    return ({name: '', type: 'catalog'})
  }

  const sortCatalogs = (catalogs) => {
    catalogs.sort((a, b) => (a.caption || '').localeCompare(b.caption || ''))
  }

  /*const ShopListItem = (props) => {
    return (

    )
  }*/

  const CatalogRow = (props) => {
    const typesByKey = {
      promotion: 'Promotion',
      catalogue: 'Catalogue',
      brochure: 'Brochure'
    }
    return [
      (globalData?.user?.role === 'annuaire'
        ?<TableCell key='thumbnail'>{props.document && <a href={`https://intranetv2.comptoirdelours.fr/api/catalog/download/${props.document}/${props.name}`} target="_blank"><img height={200} src={'/api/media/thumbnail/' + props.document} /></a>}</TableCell>
        :<TableCell key='thumbnail'>{props.document && <img height={200} src={'/api/media/thumbnail/' + props.document} />}</TableCell>),
      <TableCell key='name'>{props.name}</TableCell>,
      <TableCell key='type'>{typesByKey[props.type]}</TableCell>
    ]
  }

  const CatalogForm = (props) => {
    const fileInputRef = useRef()

    const openFileInput = () => {
      if (fileInputRef) {
        fileInputRef.current.click()
      }
    }

    const setDocument = (e) => {
      let data = new FormData()
      data.append('file', fileInputRef.current.files[0])
      ajax.put('/api/document', data).then((media) => {
        props.setEditedResource(prevState => ({...prevState, document: media._id})); props.setReadyToSave(false); props.setNeedsSaving(true)
      })
    }

    const handleChange = e => {
      const { name, value } = e.target;
      props.setEditedResource(prevState => ({...prevState, [name]: value}))
      props.setNeedsSaving(true)
    }

    return (
      <Card style={props.style}>
        <CardContent>
          <Grid container wrap='nowrap' direction='column' spacing={5}>
            <Grid item>{props.textInput('name', 'Nom du catalogue')}</Grid>
            {
              props.editedResource.document && (
                <Grid item>
                  <img height={400} style={{display: 'block', cursor: 'pointer'}} src={'/api/media/thumbnail/' + props.editedResource.document} onClick={() => { openFileInput() }} />
                </Grid>
              )
            }
            {
              globalData?.user?.role === 'annuaire' && (
                <>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={() => { openFileInput() }}>
                      <FontAwesomeIcon icon='file' style={{marginRight: '15px'}} />
                      Sélectionner un fichier
                    </Button>
                  </Grid>

                  <Grid item>
                    <RadioGroup row name='type' onChange={handleChange}>
                      <FormControlLabel value='catalogue' control={<Radio />} label='Catalogue' checked={props.editedResource.type === 'catalogue'} />
                      <FormControlLabel value='promotion' control={<Radio />} label='Promotion' checked={props.editedResource.type === 'promotion'} />
                      <FormControlLabel value='brochure' control={<Radio />} label='Brochure' checked={props.editedResource.type === 'brochure'} />
                    </RadioGroup>
                  </Grid>
                </>
              ) 
            }
            
          </Grid>

          {
            globalData?.user?.role === 'annuaire' && (
              <CardActions style={{marginTop: '30px'}}>
                {
                  props.needsSaving && (
                    <Button variant="contained" color="primary" onClick={props.save}>
                      Enregistrer
                    </Button>
                  )
                }
                <Button variant="contained" color="secondary" onClick={props.cancel}>
                  Annuler
                </Button>
              </CardActions>
            )
          }
          
        </CardContent>

        <input type='file' accept="application/pdf" ref={fileInputRef} style={{display: 'none'}} onChange={setDocument} />
      </Card>
    )
  }

  return (
    <>
      <ResourceList
        resourceType='catalog'
        filterFields={['name', 'type']}
        addLabel="Ajouter un catalogue"
        removeLabel="Suppression d'un catalogue"
        removeQuestion={removeQuestion}
        headers={['Aperçu', 'Nom', 'Type']}
        resourceRow={CatalogRow}
        resourceForm={CatalogForm}
        pictureFields={[]}
        getNewResource={getNewCatalog}
        sortResources={sortCatalogs}
        allowMarketing
        noActions={globalData?.user?.role === 'annuaire'}
      />
    </>
  )
}

export default Catalogs
