import React, {useState, useEffect} from "react";
import {Table, Form, Col, Modal } from 'react-bootstrap';
import {TableCell, Button, Grid, Card, CardContent, CardActions, TextField} from '@material-ui/core'
import './Users.scss'
import ajax from '../utils/ajax'
import Media from '../components/Media/Media'
import ResourceList from '../components/ResourceList/ResourceList'
import { Autocomplete } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from "@material-ui/core"
import DeleteIcon from '@material-ui/icons/Delete';

library.add(fas)

const statusLabel = {
  'enabled': 'Accès accordé',
  'invited': 'Invitation envoyée',
  'notInvited': "En attente d'invitation"
}

export const Users = () => {
  const [affiliates, setAffiliates] = useState(null)
  const [affiliateList, setAffiliateList] = useState(null)
  const fetch = () => {
    ajax.get('/api/affiliates').then(r => {
      let aff = {}
      for (let affiliate of r) {
        aff[affiliate._id] = affiliate
      }
      setAffiliates(aff)
      r.sort((a, b) => a.name.localeCompare(b.name))
      setAffiliateList(r)
    })
  }
  useEffect(fetch, [])

  const status = (user) => {
    if (!user.email) return "Adresse email manquante"
    else {
      return (
        <div>
          <div>{statusLabel[user.status]}</div>
          {
            user.status !== 'enabled' && (
              <Button variant="outlined" color='primary' size="small" onClick={invite.bind(null, user._id)} style={{marginTop: '5px'}}>
                {user.status === 'notInvited' ? "Inviter" : "Renvoyer l'invitation"}
              </Button>
            )
          }

        </div>
      )
    }
  }

  const invite = (id) => {
    ajax.post('/api/user/send_invitation', {_id: id}).then(fetch)
  }

  const sortUsers = (users) => {
    users.sort((a, b) => a.lastName.localeCompare(b.lastName))
  }

  const userRoles = {
    'admin': 'Administrateur',
    'adherent': 'Adhérent',
    'formateur': "Formateur",
    'commercial': 'Commercial',
    'commercial+fr': 'Commercial + FR/Tarifs',
    'marketing': "Marketing / E-Commerce / Produits",
    'vendeur': "Vendeur",
    'reyt': "Collaborateur Reyt",
    "annuaire": "Annuaires/Catalogues"
  }

  const UserRow = (props) => {
    if (!affiliates) return null

    return (
      [
        <TableCell key='picture'>{props.picture && <img height={80} src={'/api/media/thumbnail/' + props.picture} />}</TableCell>,
        <TableCell key='name'>{props.firstName + ' ' + props.lastName.toUpperCase()}</TableCell>,
        <TableCell key='email'>{props.email}</TableCell>,
        <TableCell key='role'>{props.role && userRoles[props.role]}</TableCell>,
        <TableCell key='adherent'>{props.affiliate ? (
            props.affiliate.filter(aff => affiliates[aff]).map((aff, i) => <div key={i}>{affiliates[aff].name}</div>)
          ) : ""}
        </TableCell>,
        <TableCell key='status'>{status(props)}</TableCell>
      ]
    )
  }

  const UserForm = (props) => {
    if (!affiliateList) return null

    const setAffiliate = (i, aff) => {
      let newArray = [...props.editedResource.affiliate.filter(aff => (aff === null || affiliates[aff]))]
      newArray[i] = aff
      props.setEditedResource(prevState => ({...prevState, affiliate: newArray}))
      props.setReadyToSave(false)
      props.setNeedsSaving(true)
    }
    const removeAffiliate = (i) => {
      let newArray = [...props.editedResource.affiliate.filter(aff => (aff === null || affiliates[aff]))]
      newArray.splice(i, 1)
      props.setEditedResource(prevState => ({...prevState, affiliate: newArray}))
      props.setReadyToSave(false)
      props.setNeedsSaving(true)
    }
    const addAffiliate = () => {
      let newArray = []
      if (props.editedResource.affiliate) newArray = [...props.editedResource.affiliate.filter(aff => (aff === null || affiliates[aff]))]
      newArray.push(null)
      props.setEditedResource(prevState => ({...prevState, affiliate: newArray}))
      props.setReadyToSave(false)
      props.setNeedsSaving(true)
    }

    return (
      <Card>
        <CardContent>
          <Grid container item direction='column' xs={12} md={12} spacing={2}>
            <Grid container item direction='row' alignItems='center' spacing={2}>
              <Grid item xs={12} md={5}><Media _id={props.editedResource.picture} editable onEdit={(img) => { props.setEditedResource(prevState => ({...prevState, picture: img})); props.setReadyToSave(false); props.setNeedsSaving(true) }} /></Grid>
              <Grid item xs={12} md={7} container spacing={2} direction='column'>
                <Grid container item spacing={2}>
                  <Grid item>{props.textInput('firstName', 'Prénom')}</Grid>
                  <Grid item>{props.textInput('lastName', 'Nom')}</Grid>
                </Grid>
                <Grid item>{props.textInput('email', 'Adresse email')}</Grid>
                <Grid item>{props.textInput('function', 'Fonction')}</Grid>
                {
                  props.editedResource.affiliate
                  ? props.editedResource.affiliate.filter(aff => (aff === null || affiliates[aff])).map((affiliate, i) => {
                    return (
                      <Grid item container direction='row'>
                        <Grid item style={{flexGrow: 1, flexShrink: 1, minWidth: 0}}>
                          <Autocomplete options={affiliateList.map(a => a._id)} getOptionLabel={(opt) => affiliates[opt].name} value={affiliate} onChange={(e, aff) => { setAffiliate(i, aff) }} renderInput={(params) => <TextField {...params} label="Adhérent" variant="outlined" />} />
                        </Grid>
                        <IconButton color="secondary" onClick={removeAffiliate.bind(null, i)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )
                  })
                  : null
                }
                <Grid item>
                  <Button variant="contained" color="primary" onClick={addAffiliate}>
                    <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                    Ajouter un adhérent
                  </Button>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item>{props.textInput('phone', 'Téléphone fixe')}</Grid>
                  <Grid item>{props.textInput('mobile', 'Téléphone mobile')}</Grid>
                </Grid>
                <Grid item>
                  <Autocomplete options={Object.keys(userRoles)} getOptionLabel={(opt) => (userRoles[opt])} value={props.editedResource.role || 'adherent'} onChange={(e, r) => { props.setEditedResource(prevState => ({...prevState, role: r})); props.setReadyToSave(false); props.setNeedsSaving(true) }} renderInput={(params) => <TextField {...params} label="Rôle" variant="outlined" />} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <CardActions style={{marginTop: '30px'}}>
            {
              props.needsSaving && (
                <Button variant="contained" color="primary" onClick={props.save}>
                  Enregistrer
                </Button>
              )
            }
            <Button variant="contained" color="secondary" onClick={props.cancel}>
              Annuler
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    )
  }

  const removeQuestion = (user) => {
    return `Voulez-vous vraiment supprimer l'utilisateur ${user.firstName} ${user.lastName} ?`
  }

  const getNewResource = () => {
    return {status: 'notInvited', role: 'adherent'}
  }

  return (
    <ResourceList
      resourceType='user'
      sortResources={sortUsers}
      filterFields={['firstName', 'lastName', 'email', 'phone', 'mobile']}
      addLabel="Ajouter un utilisateur"
      removeLabel="Suppression d'un utilisateur"
      removeQuestion={removeQuestion}
      headers={['Photo', 'Nom', 'Email', 'Rôle', 'Adhérent', 'Statut']}
      resourceRow={UserRow}
      resourceForm={UserForm}
      pictureFields={['picture']}
    />
  )
}

export default Users
